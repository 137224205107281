import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const TeachingQuality = ({ component, colorIcon, theme }) => (
  <section className="teachingquality-section">
    <div className="teachingquality-content">
      <div className="teachingquality-grid">
        <div>
          <div className="teachingquality-box">
            <div className="teachingquality-icon-box">
              <div className="teachingquality-icon-before" />
              <GatsbyImage
                image={getImage(component.iconoDeCalidadDocente)}
                alt=""
                className={`teachingquality-icon icon-${colorIcon}`}
              />
            </div>
            <p className="teachingquality-title">Calidad Docente</p>
            <p className="teachingquality-text">
              {component.descripcionDeCalidadDocente}
            </p>
          </div>
        </div>
        <div>
          <div className="teachingquality-box">
            <div className="teachingquality-icon-box">
              <div className="teachingquality-icon-before" />
              <GatsbyImage
                image={getImage(component.iconoDeBecas)}
                alt=""
                className={`teachingquality-icon icon-${colorIcon}`}
              />
            </div>
            <p className="teachingquality-title">Becas y Convenios</p>
            <p className="teachingquality-text">
              {component.descripcionDeBecasYConvenios}
            </p>
            <Link to="/beneficios" style={{ textDecoration: "none" }}>
              <button className="benefits-button" style={{ backgroundColor: theme }}>Me Interesa</button>
            </Link>
            <br />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default TeachingQuality
