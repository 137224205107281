import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
import WorkEnvironment from "../components/WorkEnvironment"
import TitleUndergrad from "../components/TitleUndergrad"
import WhoCenhies from "../components/WhoCenhies"
import TeachingQuality from "../components/TeachingQuality"

export const pageQuery = graphql`
  query PageTemplateUniversityQuery($id: String!) {
    graphCmsB3PaginasdeLicenciatura(id: { eq: $id }) {
      tituloDeLaPagina
      colorDeLaPagina
      componentenivel1delaLicenciatura {
        ... on GraphCMS_C1TituloEImagenSecundaria {
          id
          imagen {
            gatsbyImageData
          }
          titulo
          descripcion {
            raw
          }
          posicionDelCuadroDeTexto
        }
      }
      componentenivel2delaLicenciatura {
        ... on GraphCMS_C2CampoLaboral {
          id
          imagenDeFondo {
            gatsbyImageData
          }
          descripcionDelCampoLaboral {
            raw
          }
          posicionDelCampoDeTexto
        }
      }
      componentenivel3delaLicenciatura {
        ... on GraphCMS_C3CalidadDocenteYBecas {
          id
          iconoDeCalidadDocente {
            gatsbyImageData
          }
          descripcionDeCalidadDocente
          iconoDeBecas {
            gatsbyImageData
          }
          descripcionDeBecasYConvenios
        }
      }
      componentenivel4delaLicenciatura {
        ... on GraphCMS_C4PorQueCenhies {
          id
          imagen {
            gatsbyImageData(placeholder: BLURRED)
          }
          descripcion {
            raw
          }
        }
      }
    }
  }
`

const PageMastery = ({ data: { graphCmsB3PaginasdeLicenciatura } }) => {
  let theme =
    graphCmsB3PaginasdeLicenciatura.colorDeLaPagina === "Rojo"
      ? "#ff0000"
      : graphCmsB3PaginasdeLicenciatura.colorDeLaPagina === "Azul"
      ? "#155d7c"
      : graphCmsB3PaginasdeLicenciatura.colorDeLaPagina === "Cian"
      ? "#70c4a4"
      : graphCmsB3PaginasdeLicenciatura.colorDeLaPagina === "Verde_Lima"
      ? "#4b7c1c"
      : graphCmsB3PaginasdeLicenciatura.colorDeLaPagina === "Verde_Obscuro"
      ? "#0a6326"
      : graphCmsB3PaginasdeLicenciatura.colorDeLaPagina === "Rosa"
      ? "#b30667"
      : graphCmsB3PaginasdeLicenciatura.colorDeLaPagina === "Morado"
      ? "#4a017d"
      : "#ff0000"

  let colorIcon =
    graphCmsB3PaginasdeLicenciatura.colorDeLaPagina === "Rojo"
      ? "red"
      : graphCmsB3PaginasdeLicenciatura.colorDeLaPagina === "Azul"
      ? "blue"
      : graphCmsB3PaginasdeLicenciatura.colorDeLaPagina === "Cian"
      ? "cian"
      : graphCmsB3PaginasdeLicenciatura.colorDeLaPagina === "Verde_Lima"
      ? "lightgreen"
      : graphCmsB3PaginasdeLicenciatura.colorDeLaPagina === "Verde_Obscuro"
      ? "darkgreen"
      : graphCmsB3PaginasdeLicenciatura.colorDeLaPagina === "Rosa"
      ? "pink"
      : graphCmsB3PaginasdeLicenciatura.colorDeLaPagina === "Morado"
      ? "purple"
      : "red"

  return (
    <>
      <Seo title={graphCmsB3PaginasdeLicenciatura.tituloDeLaPagina} />
      <section className="coursepage-section">
        {graphCmsB3PaginasdeLicenciatura.componentenivel1delaLicenciatura ? (
          <TitleUndergrad
            component={
              graphCmsB3PaginasdeLicenciatura.componentenivel1delaLicenciatura
            }
            theme={theme}
            colorIcon={colorIcon}
          />
        ) : (
          ""
        )}
        {graphCmsB3PaginasdeLicenciatura.componentenivel2delaLicenciatura ? (
          <WorkEnvironment
            component={
              graphCmsB3PaginasdeLicenciatura.componentenivel2delaLicenciatura
            }
            theme={theme}
            colorIcon={colorIcon}
          />
        ) : (
          ""
        )}
        {graphCmsB3PaginasdeLicenciatura.componentenivel3delaLicenciatura ? (
          <TeachingQuality
            component={
              graphCmsB3PaginasdeLicenciatura.componentenivel3delaLicenciatura
            }
            theme={theme}
            colorIcon={colorIcon}
          />
        ) : (
          ""
        )}
        {graphCmsB3PaginasdeLicenciatura.componentenivel4delaLicenciatura ? (
          <WhoCenhies
            component={
              graphCmsB3PaginasdeLicenciatura.componentenivel4delaLicenciatura
            }
            theme={theme}
            colorIcon={colorIcon}
          />
        ) : (
          ""
        )}
      </section>
    </>
  )
}

export default PageMastery
