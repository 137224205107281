import * as React from "react"
import { RichText } from "@graphcms/rich-text-react-renderer"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const WhoCenhies = ({ component, theme, colorIcon }) => (
  <section className="whocenhies-section">
    <div className="whocenhies-content">
      <div className="whocenhies-grid">
        <div className="whocenhies-div-justify">
          <div className="whocenhies-box-title-grid">
            <div />
            <div
              className="whocenhies-cube"
              style={{ backgroundColor: `${theme}` }}
            />
            <p className="whocenhies-title">¿Por qué en CENHIES?</p>
            <div
              className="whocenhies-cube"
              style={{ backgroundColor: `${theme}` }}
            />
            <div />
          </div>
          <p className="whocenhies-text">
            <RichText content={component.descripcion.raw} />
          </p>
        </div>
        <div className="whocenhies-div">
          <div
            className="whocenhies-box-before"
            style={{ backgroundColor: `${theme}` }}
          />
          <GatsbyImage
            image={getImage(component.imagen)}
            alt=""
            className="whocenhies-image"
          />
        </div>
      </div>
    </div>
  </section>
)

export default WhoCenhies
