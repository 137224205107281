import * as React from "react"
import { RichText } from "@graphcms/rich-text-react-renderer"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const WorkEnvironment = ({ theme, component }) => (
  <section className="workenvironment-section">
    {component.posicionDelCampoDeTexto === "Derecha" ? (
      <div className="workenvironment-content">
        <GatsbyImage
          image={getImage(component.imagenDeFondo)}
          alt=""
          style={{ borderColor: `${theme}` }}
          className="workenvironment-image"
        />
        <div className="workenvironment-box-div">
          <div className="workenvironment-box">
            <div className="workenvironment-box-title-grid">
              <div />
              <div
                className="workenvironment-cube"
                style={{ backgroundColor: `${theme}` }}
              />
              <p className="workenvironment-title">Campo Laboral</p>
              <div
                className="workenvironment-cube"
                style={{ backgroundColor: `${theme}` }}
              />
              <div />
            </div>
            <p className="workenvironment-text">
              <RichText content={component.descripcionDelCampoLaboral.raw} />
            </p>
          </div>
          <div
            style={{ backgroundColor: `${theme}` }}
            className="workenvironment-description-shape"
          />
        </div>
      </div>
    ) : (
      <div className="workenvironment-content">
        <div className="workenvironment-box-div">
          <div className="workenvironment-box-left">
            <div className="workenvironment-box-title-grid">
              <div />
              <div
                className="workenvironment-cube"
                style={{ backgroundColor: `${theme}` }}
              />
              <p className="workenvironment-title">Campo Laboral</p>
              <div
                className="workenvironment-cube"
                style={{ backgroundColor: `${theme}` }}
              />
              <div />
            </div>
            <p className="workenvironment-text">
              <RichText content={component.descripcionDelCampoLaboral.raw} />
            </p>
          </div>
          <div
            style={{ backgroundColor: `${theme}` }}
            className="workenvironment-description-shape-left"
          />
        </div>
        <GatsbyImage
          image={getImage(component.imagenDeFondo)}
          alt=""
          style={{ borderColor: `${theme}` }}
          className="workenvironment-image-right"
        />
      </div>
    )}
  </section>
)

export default WorkEnvironment
